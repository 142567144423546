@import 'lib/_media.scss';


// page structure
$page-width: 1024px;
$nav-height: 60px;

// z-index
$z-index--nav: 100;
$z-index--hero: -1; // force behind everything else

// colors
$color--highlight: #9CB7FF;
$color--highlight-dark: #4E5B80;
$color--highlight-light: #D8E5E6;
$color--gray: #7C8E8F;
$color--text: black;
$color--nav: $color--highlight;
$background--gray: #F8F8F8;
$background--white: #fff;

// spacing
$spacing--xs: 4px;
$spacing--sm: 8px;
$spacing--md: 16px;
$spacing--lg: 24px;
$spacing--xl: 48px;
$spacing--2xl: 64px;
$spacing--3xl: 88px;

// font sizes
$text--xs: 11px;
$text--sm: 12px;
$text--md: 16px;
$text--lg: 24px;
$text--xl: 48px;
$text--2xl: 64px;
$text--3xl: 88px;

// line height
$line-height--none: 1;
$line-height--medium: 1.33;

// border radius
$border-radius--sm: 4px;
$border-radius--md: 8px;

// Adds negative margin to content within PageWrapper to force it full-width
@mixin fullWidth {
  margin-left: calc((100vw - 100%) / -2);
  margin-right: calc((100vw - 100%) / -2);
}

// Adds the same padding as the PageWrapper. Use to add content within a full-width div
@mixin pageWrapperSpacing {
  max-width: $page-width;
  margin: 0 auto !important;

  @include smOnly {
    margin: 0 $spacing--lg;
  }
}